import React, { useState } from "react"
import { Link } from "gatsby"

import { Tabs } from 'antd';

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactForm from "../components/ContactForm"


import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

//Images
import Grovana_m_prez from "../images/images/grovana/Grovana_prez.jpg"

import Grovana_m_001 from "../images/images/grovana/men/grovana_m_001.jpg"
import Grovana_m_002 from "../images/images/grovana/men/grovana_m_002.jpg"
import Grovana_m_003 from "../images/images/grovana/men/grovana_m_003.jpg"
import Grovana_m_004 from "../images/images/grovana/men/grovana_m_004.jpg"
import Grovana_m_005 from "../images/images/grovana/men/grovana_m_005.jpg"
import Grovana_m_006 from "../images/images/grovana/men/grovana_m_006.jpg"
import Grovana_m_007 from "../images/images/grovana/men/grovana_m_007.jpg"
import Grovana_m_008 from "../images/images/grovana/men/grovana_m_008.jpg"
import Grovana_m_009 from "../images/images/grovana/men/grovana_m_009.jpg"
import Grovana_m_010 from "../images/images/grovana/men/grovana_m_010.jpg"
import Grovana_m_011 from "../images/images/grovana/men/grovana_m_011.jpg"
import Grovana_m_012 from "../images/images/grovana/men/grovana_m_012.jpg"
import Grovana_m_013 from "../images/images/grovana/men/grovana_m_013.jpg"
import Grovana_m_014 from "../images/images/grovana/men/grovana_m_014.jpg"
import Grovana_m_015 from "../images/images/grovana/men/grovana_m_015.jpg"
import Grovana_m_016 from "../images/images/grovana/men/grovana_m_016.jpg"
import Grovana_m_017 from "../images/images/grovana/men/grovana_m_017.jpg"
import Grovana_m_018 from "../images/images/grovana/men/grovana_m_018.jpg"
import Grovana_m_019 from "../images/images/grovana/men/grovana_m_019.jpg"
import Grovana_m_020 from "../images/images/grovana/men/grovana_m_020.jpg"
import Grovana_m_021 from "../images/images/grovana/men/grovana_m_021.jpg"
import Grovana_m_022 from "../images/images/grovana/men/grovana_m_022.jpg"
import Grovana_m_023 from "../images/images/grovana/men/grovana_m_023.jpg"

import Grovana_w_001 from "../images/images/grovana/women/grovana_w_001.jpg"
import Grovana_w_002 from "../images/images/grovana/women/grovana_w_002.jpg"
import Grovana_w_003 from "../images/images/grovana/women/grovana_w_003.jpg"
import Grovana_w_004 from "../images/images/grovana/women/grovana_w_004.jpg"
import Grovana_w_005 from "../images/images/grovana/women/grovana_w_005.jpg"
import Grovana_w_006 from "../images/images/grovana/women/grovana_w_006.jpg"
import Grovana_w_007 from "../images/images/grovana/women/grovana_w_007.jpg"
import Grovana_w_008 from "../images/images/grovana/women/grovana_w_008.jpg"
import Grovana_w_009 from "../images/images/grovana/women/grovana_w_009.jpg"
import Grovana_w_010 from "../images/images/grovana/women/grovana_w_010.jpg"
import Grovana_w_011 from "../images/images/grovana/women/grovana_w_011.jpg"
import Grovana_w_012 from "../images/images/grovana/women/grovana_w_012.jpg"
import Grovana_w_013 from "../images/images/grovana/women/grovana_w_013.jpg"
import Grovana_w_014 from "../images/images/grovana/women/grovana_w_014.jpg"
import Grovana_w_015 from "../images/images/grovana/women/grovana_w_015.jpg"
import Grovana_w_016 from "../images/images/grovana/women/grovana_w_016.jpg"
import Grovana_w_017 from "../images/images/grovana/women/grovana_w_017.jpg"

import { Button, Input, Modal, Tag } from "antd"


const options = {
  overlayColor: "rgba(44,47,76,0.9)",
  captionStyle: {
    captionColor: "#857e74",
    captionFontFamily: "Raleway, sans-serif",
    captionFontSize: "22px",
    captionFontWeight: "300",
    captionFontStyle: "capitalize",
  },
  buttonsStyle: {
    buttonsBackgroundColor: "#857e74",
    buttonsIconColor: "rgba(176,158,111,0.8)",
  },
  autoplaySpeed: 1500,
  transitionSpeed: 0,
  showCaption: false,
  showThumbnails: false,
}

const Horlogeries_yvan_monet = () => {

  const { TabPane } = Tabs;

  //Hooks
  const [dataContact, setDataContact] = useState(false)

  //Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = (value_i) => {
    setShowModalContact(true)
    setDataContact(value_i)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

  return (
    <>
      <Layout>
        <SEO title="Montres Grovana"/>

        <div className="slideshow">
          <div className="usp">
            <div className="usp-txt-header">Grovana</div>
          </div>
        </div>
        <div className="border-gold-bottom"></div>


        <section>

          <div className="section-flex-01" style={{ margin: "0px" }}>

            <h2 className="h2-mobile"  style={{textAlign: 'center'}}>Grovana</h2>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="watch-intro">
                <img className="img" style={{ margin: "0px", marginTop: "6%", borderRadius: "12px" }} src={Grovana_m_prez}
                     alt="Grovana"/>
                <p className="p-2 p-font">
                  Créée en 1924 et basée à Bâle (Suisse), Grovana est une entreprise familiale.
                  Cette marque horlogères produit (depuis plus de 90 ans) des montres de haute qualité avec des prix très
                  attractifs.
                  Tous leurs modèles sont garantis « swissmade » et sont munis de verres saphirs.
                  La satisfaction de leurs clients est une priorité.
                  Ils ont un service après-vente très réactif.
                  Montres sportives, classiques et élégantes. Vous trouverez sur notre site quelques exemples de leurs
                  montres.
                </p>
              </div>
              <SimpleReactLightbox>
                <div className="h4-2">Homme</div>
                <SRLWrapper options={options}>
                  <div id="gallery-with-links" className="container content content-gallery">
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_001} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_001}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_002} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_002}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_003} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_003}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_004} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_004}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_005} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_005}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_006} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_006}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_007} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_007}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_008} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_008}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_009} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_009}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_010} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_010}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_011} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_011}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_012} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_012}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_013} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_013}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_014} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_014}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_015} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_015}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_016} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_016}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_017} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_017}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_018} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_018}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_019} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_019}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_020} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_020}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_021} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_021}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_022} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_022}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_m_023} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_023}
                                                                        alt="Grovana"/></a>
                    </div>
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>
              <SimpleReactLightbox>
                <div className="h4-2">Femme</div>
                <SRLWrapper options={options}>
                  <div id="gallery-with-links" className="container content content-gallery">
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_001} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_001}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_002} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_002}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_003} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_003}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_004} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_004}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_005} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_005}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_006} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_006}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_007} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_007}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_008} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_008}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_009} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_009}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_010} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_010}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_011} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_011}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_012} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_012}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_013} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_013}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_014} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_014}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_015} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_015}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_016} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_016}
                                                                        alt="Grovana"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Grovana_w_017} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_017}
                                                                        alt="Grovana"/></a>
                    </div>
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>
              <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Grovana') }>Nous contacter</Button></div>
            </div>

          </div>
        </section>

        <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
          visible={ showModalContact }
          onOk={ handleContactFormOk }
          onCancel={ handleContactFormCancel }
          okButtonProps={{ style: { display: 'none' } }}
        >

          <ContactForm page={dataContact} />

        </Modal>

      </Layout>
    </>
  )
}

export default Horlogeries_yvan_monet
